import request from '@/api/request'
import { to } from '@/utils/utils'

const api = {
    list: '/api/v2/merchant/recharge-price-level/list',
    add: '/api/v2/merchant/recharge-price-level/create',
    remove: '/api/v2/merchant/recharge-price-level/del',
    info: '/api/v2/merchant/recharge-price-level/get',
    edit: '/api/v2/merchant/recharge-price-level/update',

}

export const NewRechargeApi = {
    detail: '/api/v2/merchant/recharge/denomination/detail',
    export: '/api/v2/merchant/recharge/denomination/export',
    create: '/api/v2/merchant/recharge/denomination/create',
    list: '/api/v2/merchant/recharge/denomination/list',
    switch: '/api/v2/merchant/recharge/denomination/status/update',
    update: '/api/v2/merchant/recharge/denomination/update',
    remove: '/api/v2/merchant/recharge/denomination/delete',
    coinsCreate: '/api/v2/merchant/recharge/conf/create',
    coinsDetail: '/api/v2/merchant/recharge/conf/detail',
    coinsUpdate: '/api/v2/merchant/recharge/conf/update',
}

export const addRechargeValue = (data: any) => {
    return request({
        url: api.add,
        method: 'post',
        data
    })
}

export const getRechargeValueList = (params: any) => {
    return request({
        url: api.list,
        method: 'get',
        params
    })
}

export const removeRechargeValue = (data: any) => {
    return request({
        url: api.remove,
        method: 'post',
        data
    })
}

export const getRechargeValueInfo = (params: any) => {
    return request({
        url: api.info,
        method: 'get',
        params
    })
}

export const editRechargeValue = (data: any) => {
    return request({
        url: api.edit,
        method: 'post',
        data
    })
}

export const detailRechargeNew = (params: any) => {
    return request({
        url: NewRechargeApi.detail,
        method: 'get',
        params
    })
}

export const createRechargeValueNew = (data: any) => {
    return to(request({
        url: NewRechargeApi.create,
        method: 'post',
        data
    }))
}

export const queryRechargeValueListNew = (params: any) => {
    return request({
        url: NewRechargeApi.list,
        method: 'get',
        params
    })
}

export const rechargeValueSwitchNew = (data: any) => {
    return to(request({
        url: NewRechargeApi.switch,
        method: 'post',
        data
    }))
}

export const updateRechargeValueNew = (data: any) => {
    return to(request({
        url: NewRechargeApi.update,
        method: 'post',
        data
    }))
}

export const delRechargeValueNew = (data: any) => {
    return to(request({
        url: NewRechargeApi.remove,
        method: 'post',
        data
    }))
}

export const createRechargeCoins = (data: any) => {
    return to(request({
        url: NewRechargeApi.coinsCreate,
        method: 'post',
        data
    }))
}

export const getRechargeCoinsDetail = (params: any) => {
    return to(request({
        url: NewRechargeApi.coinsDetail,
        method: 'get',
        params
    }))
}

export const rechargeCoinsUpdate = (data: any) => {
    return to(request({
        url: NewRechargeApi.coinsUpdate,
        method: 'post',
        data
    }))
}