export enum PageEnum {
  // 官网首页
  BASE_HOME = '/',
  DOC = '/doc',
  ABOUT = '/about',
  // basic login path
  BASE_LOGIN = '/login',
  // basic home path
  BASE_DASHBOARD = '/dashboard',
  BILL = '/bill',
  BILL_DETAIL = '/bill/detail/:id',
  // 交易
  BUSINESS = '/business',
  BUSINESS_DETAIL = '/business/detail/:id',
  Bill_REISSUE = '/billReissue',
  // error page path
  ERROR_PAGE = '/exception',
  // error log page path
  ERROR_LOG_PAGE = '/error-log/list',
  // 重置密码页面
  RESET_PASSWORD_PAGE = '/resetpassword',
  // 应用首页
  APPS = '/apps',
  // 商店
  SHOP = '/apps/index',
  // 登录管理器
  LOGIN_MANAGE = '/apps/loginManage',
  // 建站器
  SITE_DESIGNER = '/siteDesigner',
  // 商品组
  GOODGROUP = '/apps/goodGroup',
  // 商品列表
  GOODGS = '/apps/goods',
  // 应用设置
  APP_INFO = '/apps/appInfo',
  // 商品详情
  DOOGDETAIL = '/apps/goodDetail',
  // 动态列表demo
  DEMO_TABLE = '/demo/dynamicTable',
  // 测试页面
  DEMO = '/demo',
  // airwallex
  SERVICE_PRIVACY = '/service/privacy',
  SERVICE_TERMS = '/service/terms',
  SERVICE_REFUND = '/service/refund',
  SERVICE_PAYMENT = '/service/payment',
  // 分析页面
  ANALYZE = '/analyze',
  // 新版分析页面
  DATA_ANALYZE = '/dataAnalyze',
  DATA_ANALYZE_WATER = '/dataAnalyze/water',
  DATA_ANALYZE_PAYER = '/dataAnalyze/payer',
  DATA_ANALYZE_RATE = '/dataAnalyze/rate',
  DATA_ANALYZE_RANK = '/dataAnalyze/rank',
  DATA_ANALYZE_VISIT = '/dataAnalyze/visit',
  AUTH_MANAGEMENT = '/authManagement',
  // 拒付页面
  CHARGEBACK = '/chargeback',
  MERCHANT_SETTLEMENT = '/merchantSettlement',
  MERCHANT_SETTING = '/merchantSetting', // 公司设置页面
  USER_SETTING = '/userSetting', // 用户设置页面
  INVITE_MEMBER = '/invite', // 邀请用户欢迎页面
  PERMISSION = '/permission', // 权限管理
  PERMISSION_INVITE_USER = '/permission/inviteUser',
  PERMISSION_USER_DETAIL = '/permission/user/:id',
  PERMISSION_CREATE_ROLE = '/permission/role/create',
  PERMISSION_ROLE_DETAIL = '/permission/role/:id',
  // 应用相关页面路由
  PROJECT_MANAGE_HOME = '/projectManage', // 项目管理首页
  PROJECT_ALL = '/projectManage/projectAll',
  PROJECT = '/projectManage/project', // 单个app渲染
  PROJECT_SETTING = '/projectManage/project/:projectId/setting', // app 设置页
  PROJECT_INDEX = '/projectManage/project/:projectId',
  PROJECT_LANGUAGE = '/projectManage/project/:projectId/storeLanguage',
  PROJECT_CURRENCY = '/projectManage/project/:projectId/currency',
  PROJECT_CLASS = '/projectManage/project/:projectId/class',
  PROJECT_GOODS_CLASSIFY = '/projectManage/project/:projectId/goodsClassify',
  PROJECT_GOODS_MANAGE = '/projectManage/project/:projectId/goodsManage',
  PROJECT_CONVERT_KEY = '/projectManage/project/:projectId/convertKey',
  PROJECT_CONVERT_KEY_CREATE = '/projectManage/project/:projectId/convertKeyCreate',
  PROJECT_CLASS_DETAIL = '/projectManage/project/:projectId/class/:goodId',
  PROJECT_ACTIVITY = '/projectManage/project/:projectId/activity',
  MONTH_CARD = '/projectManage/project/:projectId/monthCard/index',
  MONTH_CARD_EDIT = '/projectManage/project/:projectId/monthCard/create',
  PROJECT_ACTIVITY_REPLAY = '/projectManage/project/:projectId/activityReplay',
  PROJECT_ACTIVITY_REPLAY_LOTTERY_DETAIL = '/projectManage/project/:projectId/lottery/detail',
  PROJECT_RECHARGE = '/projectManage/project/:projectId/recharge',
  PROJECT_NEW_RECHARGE = '/projectManage/project/:projectId/newRecharge',
  PROJECT_NEW_RECHARGE_REBATE = '/projectManage/project/:projectId/newRecharge/rebate',
  PROJECT_RECHARGE_CURRENCY = '/projectManage/project/:projectId/recharge/currency',
  PROJECT_RECHARGE_VALUE = '/projectManage/project/:projectId/recharge/value',
  PROJECT_ACTIVITY_DETAIL = '/projectManage/project/:projectId/activity/detail/:id',
  PROJECT_PAYS_METHODS = '/projectManage/project/:projectId/pay/methods',
  PROJECT_PAYS_SORT = '/projectManage/project/:projectId/pay/sort',
  PROJECT_PAYS_CONFIG = '/projectManage/project/:projectId/pay/config',
  PROJECT_SITE_BUILD = '/projectManage/project/:projectId/siteBuild', // site builder列表页
  PROJECT_SITE_BUILD_EDITOR = '/projectManage/project/:projectId/siteBuild/editor/:domain', // site builder建站器
  PROJECT_SITE_BUILD_PREVIEW = '/projectManage/project/:projectId/siteBuild/preview/:domain/:lang', // site builder预览页
  PROJECT_USER_GROUP = '/projectManage/project/:projectId/userGroup', // 用户群体
  PROJECT_GENERAL_SETTING = '/projectManage/project/:projectId/generalSetting',
  PROJECT_WEBHOOKS_SETTING = '/projectManage/project/:projectId/webhooksSetting',
  PROJECT_PRICE_UPDATE = '/projectManage/project/:projectId/priceUpdate', // 自动更新价格
  PROJECT_PRICE_TEMPLATE = '/projectManage/project/:projectId/pricetemplate',  // 定价模版
  PROJECT_RISK_MANAGER = '/projectManage/project/:projectId/risk',  // 风险管理
  PROJECT_MULTI_LANGUAGE = '/projectManage/project/:projectId/multiLanguage',  // 多语言管理
  PERMISSION_USERS = '/permission/users',
  ACTIVITY_XY = '/projectManage/project/:projectId/activity/xy',
  ACTIVITY_XY_CREATE = '/projectManage/project/:projectId/activity/xy/create',
  ACTIVITY_ADD_UP = '/projectManage/project/:projectId/activity/addUp',
  ACTIVITY_ADD_UP_CREATE = '/projectManage/project/:projectId/activity/addUp/create',
  ACTIVITY_ADD_UP_REPLAY = '/projectManage/project/:projectId/activity/addUp/replay',
  ACTIVITY_FIRST_BUY = '/projectManage/project/:projectId/activity/firstBuy',
  ACTIVITY_FIRST_BUY_CREATE = '/projectManage/project/:projectId/activity/firstBuy/create',
  ACTIVITY_LIMIT = '/projectManage/project/:projectId/activity/limit',
  ACTIVITY_LIMIT_CREATE = '/projectManage/project/:projectId/activity/limit/create',
  ACTIVITY_RECHARGE = '/projectManage/project/:projectId/activity/recharge',
  ACTIVITY_RECHARGE_CREATE = '/projectManage/project/:projectId/activity/recharge/create',
  ACTIVITY_SIGN_IN = '/projectManage/project/:projectId/activity/signIn',
  ACTIVITY_SIGN_IN_CREATE = '/projectManage/project/:projectId/activity/signIn/create',
  ACTIVITY_SIGN_IN_REPLAY = '/projectManage/project/:projectId/activity/signIn/replay',
  ACTIVITY_CONSECUTIVE = '/projectManage/project/:projectId/activity/consecutive',
  ACTIVITY_CONSECUTIVE_REPLAY = '/projectManage/project/:projectId/activity/consecutive/replay',
  ACTIVITY_CONSECUTIVE_CREATE = '/projectManage/project/:projectId/activity/consecutive/create',
  ACTIVITY_LOTTERY = '/projectManage/project/:projectId/activity/lottery',
  ACTIVITY_LOTTERY_CREATE = '/projectManage/project/:projectId/activity/lottery/create',
  ACTIVITY_LOTTERY_REPLAY = '/projectManage/project/:projectId/activity/lottery/replay',
  ACTIVITY_FULL_DISCOUNT = '/projectManage/project/:projectId/activity/fullDiscount',
  ACTIVITY_FULL_DISCOUNT_CREATE = '/projectManage/project/:projectId/activity/fullDiscount/create',
  ACTIVITY_FULL_DISCOUNT_REPLAY = '/projectManage/project/:projectId/activity/fullDiscount/replay',
  ACTIVITY_COUPON = '/projectManage/project/:projectId/activity/coupon',
  ACTIVITY_COUPON_EDIT = '/projectManage/project/:projectId/activity/coupon/create',
  ACTIVITY_COUPON_REPLAY = '/projectManage/project/:projectId/activity/coupon/replay',
  ACTIVITY_INVITE_CODE = '/projectManage/project/:projectId/activity/inviteCode',
  REPLACE_PAYMENT = '/replacePayment',
  RECHARGE_HISTORY = '/rechargeHistory',
  WITHDRAW_CASH = '/withdrawCash',
  ORDER_PURCHASE = '/orderPurchase',
  ORDER_PURCHASE_HISTORY = '/orderPurchase/history',
  ORDER_PURCHASE_BILL = '/orderPurchase/bill',
  PROPS_LIBRARY = '/projectManage/project/:projectId/propsLibrary',
  VIP_CENTER_RANK = '/vip/rank',
  VIP_CENTER_RANK_CREATE = '/vip/rank/create',
  VIP_CENTER_POINTS = '/vip/points',
  VIP_CENTER_OVERVIEW = '/vip/overview'
}

export enum RouterNameEnum {
  BASE_HOME = 'BASE_HOME',
  DOC = 'DOC',
  ABOUT = 'ABOUT',
  BASE_LOGIN = 'BASE_LOGIN',
  BASE_DASHBOARD = 'BASE_DASHBOARD',
  BILL = 'BILL',
  BILL_DETAIL = 'BILL_DETAIL',
  BUSINESS = 'BUSINESS',
  BUSINESS_DETAIL = 'BUSINESS_DETAIL',
  Bill_REISSUE = 'Bill_REISSUE',
  ERROR_PAGE = 'ERROR_PAGE',
  PERMISSION = 'PERMISSION',
  PERMISSION_ROLE_DETAIL = 'PERMISSION_ROLE_DETAIL',
  PERMISSION_INVITE_USER = 'PERMISSION_INVITE_USER',
  PERMISSION_USER_DETAIL = 'PERMISSION_USER_DETAIL',
  PERMISSION_CREATE_ROLE = 'PERMISSION_CREATE_ROLE',
  SERVICE_PRIVACY = 'SERVICE_PRIVACY',
  SERVICE_TERMS = 'SERVICE_TERMS',
  SERVICE_REFUND = 'SERVICE_REFUND',
  SERVICE_PAYMENT = 'SERVICE_PAYMENT',
  DATA_ANALYZE = 'DATA_ANALYZE',
  DATA_ANALYZE_WATER = 'DATA_ANALYZE_WATER',
  DATA_ANALYZE_PAYER = 'DATA_ANALYZE_PAYER',
  DATA_ANALYZE_RATE = 'DATA_ANALYZE_RATE',
  DATA_ANALYZE_RANK = 'DATA_ANALYZE_RANK',
  DATA_ANALYZE_VISIT = 'DATA_ANALYZE_VISIT',
  CHARGEBACK = 'CHARGEBACK',
  MERCHANT_SETTLEMENT = 'MERCHANT_SETTLEMENT',
  MERCHANT_SETTING = 'MERCHANT_SETTING',
  USER_SETTING = 'USER_SETTING',
  INVITE_MEMBER = 'INVITE_MEMBER',
  MONTH_CARD_EDIT = 'MONTH_CARD_EDIT',
  MONTH_CARD = 'MONTH_CARD',
  PROJECT_MANAGE_HOME = 'PROJECT_MANAGE_HOME',
  PROJECT_INDEX = 'PROJECT_INDEX',
  PROJECT_SITE_BUILD = 'PROJECT_SITE_BUILD',
  PROJECT_SITE_BUILD_EDITOR = 'PROJECT_SITE_BUILD_EDITOR',
  PROJECT_SITE_BUILD_PREVIEW = 'PROJECT_SITE_BUILD_PREVIEW',
  PROJECT_ALL = 'PROJECT_ALL',
  PROJECT_RECHARGE = 'PROJECT_RECHARGE',
  PROJECT_RECHARGE_CURRENCY = 'PROJECT_RECHARGE_CURRENCY',
  PROJECT_RECHARGE_VALUE = 'PROJECT_RECHARGE_VALUE',
  PROJECT_CURRENCY = 'PROJECT_CURRENCY',
  PROJECT_CONVERT_KEY_CREATE = 'PROJECT_CONVERT_KEY_CREATE',
  PROJECT_CLASS = 'PROJECT_CLASS',
  PROJECT_GOODS_CLASSIFY = 'PROJECT_GOODS_CLASSIFY',
  PROJECT_GOODS_MANAGE = 'PROJECT_GOODS_MANAGE',
  PROJECT_CONVERT_KEY = 'PROJECT_CONVERT_KEY',
  PROJECT_ACTIVITY = 'PROJECT_ACTIVITY',
  PROJECT_ACTIVITY_DETAIL = 'PROJECT_ACTIVITY_DETAIL',
  PROJECT_ACTIVITY_REPLAY = 'PROJECT_ACTIVITY_REPLAY',
  PROJECT_ACTIVITY_REPLAY_LOTTERY_DETAIL = 'PROJECT_ACTIVITY_REPLAY_LOTTERY_DETAIL',
  PROJECT_PAYS_METHODS = 'PROJECT_PAYS_METHODS',
  PROJECT_PAYS_SORT = 'PROJECT_PAYS_SORT',
  PROJECT_PAYS_CONFIG = 'PROJECT_PAYS_CONFIG',
  PROJECT_USER_GROUP = 'PROJECT_USER_GROUP',
  PROJECT_GENERAL_SETTING = 'PROJECT_GENERAL_SETTING',
  PROJECT_WEBHOOKS_SETTING = 'PROJECT_WEBHOOKS_SETTING',
  PROJECT_PRICE_UPDATE = 'PROJECT_PRICE_UPDATE',
  PROJECT_PRICE_TEMPLATE = 'PROJECT_PRICE_TEMPLATE',  // 定价模版
  PROJECT_RISK_MANAGER = 'PROJECT_RISK_MANAGER', // 风险管理
  PROJECT_MULTI_LANGUAGE = 'PROJECT_MULTI_LANGUAGE', // 多语言管理
  PERMISSION_USERS = 'PERMISSION_USERS',
  ACTIVITY_XY = 'ACTIVITY_XY',
  ACTIVITY_XY_CREATE = 'ACTIVITY_XY_CREATE',
  ACTIVITY_ADD_UP = 'ACTIVITY_ADD_UP',
  ACTIVITY_ADD_UP_CREATE = 'ACTIVITY_ADD_UP_CREATE',
  ACTIVITY_ADD_UP_REPLAY = 'ACTIVITY_ADD_UP_REPLAY',
  ACTIVITY_FIRST_BUY = 'ACTIVITY_FIRST_BUY',
  ACTIVITY_FIRST_BUY_CREATE = 'ACTIVITY_FIRST_BUY_CREATE',
  ACTIVITY_LIMIT = 'ACTIVITY_LIMIT',
  ACTIVITY_LIMIT_CREATE = 'ACTIVITY_LIMIT_CREATE',
  ACTIVITY_RECHARGE = 'ACTIVITY_RECHARGE',
  ACTIVITY_RECHARGE_CREATE = 'ACTIVITY_RECHARGE_CREATE',
  ACTIVITY_SIGN_IN = 'ACTIVITY_SIGN_IN',
  ACTIVITY_SIGN_IN_REPLAY = 'ACTIVITY_SIGN_IN_REPLAY',
  ACTIVITY_SIGN_IN_CREATE = 'ACTIVITY_SIGN_IN_CREATE',
  ACTIVITY_CONSECUTIVE = 'ACTIVITY_CONSECUTIVE',
  ACTIVITY_CONSECUTIVE_REPLAY = 'ACTIVITY_CONSECUTIVE_REPLAY',
  ACTIVITY_CONSECUTIVE_CREATE = 'ACTIVITY_CONSECUTIVE_CREATE',
  ACTIVITY_LOTTERY = 'ACTIVITY_LOTTERY',
  ACTIVITY_LOTTERY_CREATE = 'ACTIVITY_LOTTERY_CREATE',
  ACTIVITY_LOTTERY_REPLAY = 'ACTIVITY_LOTTERY_REPLAY',
  ACTIVITY_FULL_DISCOUNT = 'ACTIVITY_FULL_DISCOUNT',
  ACTIVITY_FULL_DISCOUNT_CREATE = 'ACTIVITY_FULL_DISCOUNT_CREATE',
  ACTIVITY_FULL_DISCOUNT_REPLAY = 'ACTIVITY_FULL_DISCOUNT_REPLAY',
  ACTIVITY_INVITE_CODE = 'ACTIVITY_INVITE_CODE',
  ACTIVITY_COUPON = 'ACTIVITY_COUPON',
  ACTIVITY_COUPON_EDIT = 'ACTIVITY_COUPON_EDIT',
  ACTIVITY_COUPON_REPLAY = 'ACTIVITY_COUPON_REPLAY',
  REPLACE_PAYMENT = 'REPLACE_PAYMENT',
  RECHARGE_HISTORY = 'RECHARGE_HISTORY',
  WITHDRAW_CASH = 'WITHDRAW_CASH',
  ORDER_PURCHASE = 'ORDER_PURCHASE',
  ORDER_PURCHASE_HISTORY = 'ORDER_PURCHASE_HISTORY',
  ORDER_PURCHASE_BILL = 'ORDER_PURCHASE_BILL',
  PROPS_LIBRARY = 'PROPS_LIBRARY',
  VIP_CENTER_RANK = 'VIP_CENTER_RANK',
  VIP_CENTER_RANK_CREATE = 'VIP_CENTER_RANK_CREATE',
  VIP_CENTER_POINTS = 'VIP_CENTER_POINTS',
  VIP_CENTER_OVERVIEW = 'VIP_CENTER_OVERVIEW',
  PROJECT_NEW_RECHARGE = 'PROJECT_NEW_RECHARGE',
  PROJECT_NEW_RECHARGE_REBATE = 'PROJECT_NEW_RECHARGE_REBATE'
}
