// import cn from '@/locales/cn.json'
// import en from '@/locales/en.json'


// export function getFormatList() {
//   const i18n = {
//     cn: new Map(),
//     en: new Map()
//   }
//   const list = [
//     {key: 'cn', data: cn},
//     {key: 'en', data: en}
//   ]
//   const checkout = (prefix, data, lang) => {
//     for (const key in data) {
//       const current = data[key]
//       if (typeof current === 'object') {
//         checkout(`${prefix}.${key}`, current, lang)
//       } else if (typeof current === 'string') {
//         if (!i18n[lang].has(key)) {
//           i18n[lang].set(`${prefix}.${key}`, data[key], lang)
//         }
//       }
//     }
//   }
//   list.forEach(item => {
//     for (const key in item.data) {
//       const current = item.data[key]
//       if (typeof current === 'object') {
//         checkout(key, current, item.key)
//       }
//     }
//   })
//   const result = []
//   i18n.cn.forEach((value, key) => {
//     const item = {
//       code: key,
//       cn: value
//     }
//     result.push(item)
//   })
//   result.forEach(item => {
//     item.en = i18n.en.get(item.code)
//   })
//   return result
// }

export const getLangIdName = (names: { [x: string]: string }, currentLang: string | number, langIdData: { [x: string]: { [x: string]: string } }) => {
  let result = (names?.lang_id || names?.langId) || ''
  const langId = names['lang_id']
  if (langIdData[currentLang] && langIdData[currentLang][langId]) {
    result = langIdData[currentLang][langId]
  } else if (names[currentLang]) {
    result = names[currentLang]
  }
  return result
}