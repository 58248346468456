import { defineStore } from 'pinia'
import { getLangIdData } from '@/api/multiLanguage'


export const LanguagesStore = defineStore('languages', {
  state: () => ({
    languages: [] as any[],
    langIdData: {},
    loading: false
  }),
  actions: {
    SET_LANGUAGES(languages: any) {
      this.languages = languages
    },
    async getLanguageBaseData(project_id: string) {
      this.loading = true
      this.langIdData = await getLangIdData({ project_id })
      this.loading = false
    }
  }
})
