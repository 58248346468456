import { RouterMap, RoutePathPermissionMap } from './routesList'
import router from './index'
import { cloneDeep, isArray } from 'lodash-es'
import type { MenuChildItemInterface } from '@/types/interface/menuTree'
import type { RouteRecordNormalized } from 'vue-router'
import store from '@/stores/index'
import { PermissionStore } from '@/stores/modules/permission'
import {UserStore} from '@/stores/modules/user'
import type {UserInfoType} from '@/types/interface/userInterface'
import {getUserInfo} from '@/api/user'
import {loginOut} from '@/utils/utils'
import {PageEnum} from '@/types/enums/pageEnum'
import {message} from 'ant-design-vue'
import cookies from 'js-cookie'
import {TokenName} from '@/config/cookieKeys'

const permissionStore = PermissionStore(store)

const addRoute = (code: string, links?: [] | undefined) => {
    const insert = (c: string) => {
        const currentRoute = RouterMap.get(c)
        if (currentRoute && Array.isArray(currentRoute)) {
            currentRoute.forEach(routeItem => {
                router.addRoute(routeItem)
            })
        } else if (currentRoute) {
            router.addRoute(currentRoute)
        }
    }
    insert(code)
    links && links.forEach(item => {
        insert(item)
    })
}

export const checkRouterPermission = (path: any) => {
    const isProject = path.includes('/projectManage')
    const isRole = path.includes('/role')
    let keys = ['', '']
    if (isProject) {
        keys = [' /', ':projectId/']
    } else if (isRole) {
        keys = [' ', ':id']
    }
    const newPath = path.replace(/[0-9]/ig, ' ').replace(keys[0], keys[1]).replaceAll(' ', '')
    return RoutePathPermissionMap.get(newPath)
}

export const setUserInfo = async (next?: any) => {
    const cookiesToken = cookies.get(TokenName)
    const userStore = UserStore()
    if (!userStore.user) {
        try {
            const userInfo: UserInfoType = await getUserInfo(cookiesToken)
            if (userInfo) {
                userStore.SET_USER(userInfo)
                permissionStore.setPermission(userInfo.perm_codes)
            } else {
                loginOut()
                return next && next(PageEnum.BASE_LOGIN)
            }
        } catch (error: any) {
            message.error(error?.message || '获取用户详情失败')
            console.error(error)
        }
    }
}

export function generateRoutes() {
    permissionStore.permissions.forEach(item => {
        addRoute(item)
    })
    const permissionRouter:RouteRecordNormalized[] = cloneDeep(router.getRoutes())
    return [ permissionRouter ]
}

const noView = (data: any) => {
    let result = true
    data.functions.forEach((item: any) => {
        if (item.code.includes('View') && item.checked) {
            result = false
        }
    })

    data.children.forEach((item: any) => {
        const has = item.functions.find((funItem: any) => funItem.checked && funItem.code.includes('View'))
        if (has) {
            result = false
        }
    })

    return result
}

export const getMenuCodes = (menus: any) => {
    const result:string[] = []
    const addCode = (code: string) => {
        if (!result.includes(code)) {
            result.push(code)
        }
    }
    const hasView = (routerItem: MenuChildItemInterface) => {
        let valid = false
        routerItem.functions.forEach(item => {
            if (item.code.includes('View') && item.checked) {
                valid = true
            }
            if (item.checked) {
                addCode(item.code)
                // item.links && item.links.forEach(linkItem => addCode(linkItem))
            }
        })
        return valid
    }
    menus.forEach((item: any) => {
        const { code, functions, links } = item
        // const isDefaultShow = (!functions || functions.length === 0)
        if (!noView(item)) {
            addCode(code)
        } else if (hasView(item)) {
            addCode(code)
        }
        functions.forEach((funItem: any) => {
            if (funItem.checked) {
                addCode(funItem.code)
            }
        })
        item.children.forEach((child: MenuChildItemInterface) => {
            if (hasView(child)) {
                addCode(child.code)
            }
            child.links && child.links.forEach(linkCode => {
                addCode(linkCode)
            })
        })
        links && links.forEach((linkCode: string) => {
            addCode(linkCode)
        })
    })
    return result
}

export const checkPermission = (code: string) => {
    return permissionStore.permissions.has(code)
}

export const shakePermission = (data: [any] | object) => {
    if (isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            const item = data[i]
            if (!checkPermission(item.code)) {
                data.splice(i, 1)
                i--
            }
        }
    }
    return data
}
